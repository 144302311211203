@import "tailwindcss";

@import "../styles/animation.scss";

:root {
  --background: #ffffff;
  --foreground: #171717;
}

// @media (prefers-color-scheme: dark) {
//   :root {
//     --background: #0a0a0a;
//     --foreground: #ededed;
//   }
// }

body {
  color: var(--foreground);
  background: var(--background);
  font-family: var(--font-neue-haas-unica), "Helvetica Neue", Helvetica,
    sans-serif;
}

.stack {
  display: flex;
  flex-direction: column;
}

.rich-text {
  b {
    color: #000;
    font-weight: 500;
  }
}

.container {
  max-width: none;
  // max-width: 1332px;
  // @media (max-width: 1332px) {
  padding: 0 40px;
  // }
}
